<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row :gutter="12">
        <a-col :span="7">
          <a-form-item label="商户编号" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-input placeholder="请输入商户编号" v-model="queryParam.userNo"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item label="商户名称" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-input placeholder="商户名称" v-model="queryParam.userName"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" @click="() => {queryParam.pageNum = 1; searchQuery()}" icon="search">查询</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="createUser">创建商户</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="setUserChannelRoutes">设置商户渠道</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)"> 编辑 </a>
        <a-divider type="vertical" />
        <a @click="downloadMerchantConfig(record.userNo)"> 下载 </a>
      </span>
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启动' : status == 2 ? '禁用' : '无状态' }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParam.pageNum"
        :page-size="queryParam.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :visible="modalvisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="modalForm" v-if="status">
<!--        <a-form-item label="邮箱">-->
<!--          <a-input v-model="modalForm.email" />-->
<!--        </a-form-item>-->
<!--        <a-form-item label="手机号">-->
<!--          <a-input v-model="modalForm.mobile" />-->
<!--        </a-form-item>-->
<!--        <a-form-item label="WhatsApp">-->
<!--          <a-input v-model="modalForm.whatsapp" />-->
<!--        </a-form-item>-->
        <a-form-item label="商户名称">
          <a-input v-model="modalForm.userName" />
        </a-form-item>
        <a-form-item label="商户号">
          <a-input v-model="modalForm.userNo" />
        </a-form-item>
        <a-form-item label="代收类型">
          <a-select v-model="modalForm.payInType">
            <a-select-option v-for="item in payInTypes" :key="item.name" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="状态">
          <a-select v-model="modalForm.status">
            <a-select-option v-for="item in status" :key="item.itemValue" :value="item.itemValue">
              {{ item.itemText }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal :visible="modalvisible1" :confirm-loading="confirmLoading1" @ok="handleOk1" @cancel="handleCancel1">
      <a-form :form="modalForm1">
        <a-form-item label="商户编号">
          <a-select v-model="modalForm1.userNo">
            <a-select-option v-for="item in merchants" :key="item.userNo" :value="item.userNo">
              {{ item.userName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="渠道">
          <a-select v-model="modalForm1.channelRouteIds" mode="multiple">
            <a-select-option v-for="item in routeChannels" :key="item.channelName" :value="item.channelRouteId">
              {{ item.channelRouteName +"(" + item.channelName + ")"}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>
<script>
import {
  downloadMerchantConfig,
  findDictItemByCode,
  getMerchantPage,
  saveMerchant,
  updateMerchant,
  queryAllRouteChannels, setMerchantRouteChannels, checkMerchantChannel
} from '../../../api/pay.js'
import { message } from 'ant-design-vue/es'
import { exportFile } from '@/api/exportFile'

export default {
  name: 'merchantInfo',
  data() {
    return {
      queryParam: {
        userNo: '', //商户编号
        userName: '', //商户名称
        pageNum: 1,
        pageSize: 10,
      },
      dataSource: [],
      loading: false,
      total: 0,
      userNo: '',
      columns: [
        {
          title: '创建时间',
          align: 'left',
          width: '100px',
          dataIndex: 'createTime',
          customRender(text) {
            const date = new Date(text)
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            return `${year}-${month}-${day}`
          },
        },
        {
          title: '商户编号',
          align: 'left',
          width: '100px',
          dataIndex: 'userNo',
        },
        {
          title: '商户名称',
          align: 'left',
          width: '100px',
          dataIndex: 'userName',
        },
        {
          title: '电话',
          align: 'left',
          width: '100px',
          dataIndex: 'mobile',
        },
        {
          title: 'Whatsapp',
          align: 'left',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'whatsapp',
        },
        {
          title: '邮箱',
          align: 'left',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'email',
        },
        {
          title: '创建人',
          align: 'left',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'creator',
        },
        {
          title: '操作人',
          align: 'left',
          width: '100px',
          dataIndex: 'updater',
        },
        {
          title: '状态',
          align: 'left',
          width: '100px',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '100px',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ],
      edituserId: '',
      status: [],
      payInTypes: [
        {
          name:"接口",
          value:1
        },
        {
          name:"收银台",
          value:2
        }
      ],
      confirmLoading: false,
      confirmLoading1: false,
      modalvisible: false,
      modalvisible1: false,
      edit: false,
      editUserNo: '',
      modalForm: {
        email: '',
        mobile: '',
        whatsapp: '',
        userName: '',
        payInType: undefined,
        status: '',
        userNo: '',
      },
      merchants: [],
      routeChannels: [],
      modalForm1: {
        userNo: '',
        channelRouteIds: []
      },
    }
  },
  created() {
    findDictItemByCode('status').then((res) => {
      this.modalForm.userNo = this.userNo
      this.status = res.data.status
    })
    queryAllRouteChannels().then(
      (res) => {
        this.routeChannels = res.data
      }
    )
    getMerchantPage({ pageNum: 1, pageSize: 999 }).then((res) => {
      this.merchants = res.data.list
    })

  },
  methods: {
    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum
      this.queryParam.pageSize = pageSize
      this.searchQuery()
    },
    onUserNameChange(userId) {
      this.modalForm1.setFieldsValue({ userId })
    },
    handleEdit(row) {
      this.modalvisible = true
      this.edit = true
      let keys = Object.keys(this.modalForm)
      this.editUserNo = row.userNo
      for (let key in row) {
        keys.forEach((item) => {
          if (key == item) {
            this.modalForm[key] = row[key]
          }
        })
      }
      this.edituserId = row.userId
      if (this.modalForm.status == 1) {
        this.modalForm.status = '1'
      } else if (this.modalForm.status == 2) {
        this.modalForm.status = '2'
      } else {
        this.modalForm.status = ''
      }
    },
    downloadMerchantConfig(userNo) {
      this.loading = true
      const params = {
        userNo: userNo
      }
      checkMerchantChannel(params).then((res) => {
        if (res.code == '2000') {
          downloadMerchantConfig(params)
            .then((res) => {
              const text = res
              const blob = new Blob([text], { type: 'text/plain' })
              var country = sessionStorage.getItem('country')
              const filename = country + '_' + userNo + '.txt'
              exportFile(blob, filename)
              this.$message.success('下载成功')
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          message.error('商户未配置渠道')
          this.loading = false
          return
        }
      })
    },
    createUser() {
      // this.modalForm.userNo=Math.floor(Math.random() * (100 - 2 + 1)) + 3
      this.modalvisible = true
    },
    setUserChannelRoutes() {
      this.modalvisible1 = true
    },
    handleOk() {
      this.confirmLoading = true
      if (this.edit) {
        let data = {
          ...this.modalForm,
          userNo: this.editUserNo,
          userId: this.edituserId,
          // userId:
        }
        updateMerchant(data).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('编辑成功')
            this.searchQuery()
          }
        })
      } else {
        saveMerchant(this.modalForm).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('保存成功')
            getMerchantPage({ pageNum: 1, pageSize: 999 }).then((res) => {
              this.merchants = res.data.list
            })
            this.searchQuery()
          } else {
            message.error(res.msg)
          }
        })
      }

      this.edit = false
    },
    handleCancel() {
      this.modalvisible = false
      this.edit = false
    },
    handleOk1() {
      this.confirmLoading = true
      setMerchantRouteChannels(this.modalForm1).then((res) => {
        this.confirmLoading = false
        if (res.code == '2000') {
          this.modalvisible1 = false
          message.success('保存成功')
          this.modalForm1.userNo = ''
          this.modalForm1.channelRouteIds = []
          this.searchQuery()
        }
      })
      this.edit = false
    },
    handleCancel1() {
      this.modalvisible1 = false
    },
    searchQuery() {
      this.loading = true
      getMerchantPage(this.queryParam).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.page.total
        this.confirmLoading = false
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
</style>
